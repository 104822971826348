import config from '@/config';
import gtm from '@/commons/services/gtm';
import loggedUserResource from '@/commons/resources/loggedUser';
import { useRouter } from 'vue-router';
import { getAppRoute } from './apps';
import Cookies from 'js-cookie';
import checkpoint from '@/commons/constants/checkpoint';
import checkpointResource from '@/commons/resources/checkpoint';
import { fetchTfaState, tfaState } from '@/commons/services/tfa';

export const authenticate = async () => {
  const router = useRouter();

  if (window.location.pathname !== '/auth') {
    gtm.setup();
    return;
  }

  const url = new URL(window.location.href);
  const cookieToken = Cookies.get('trauth', { domain: '.transfeera.com' });
  if (cookieToken) {
    Cookies.remove('trauth', {
      domain: '.transfeera.com',
      secure: true,
      path: '',
      sameSite: 'strict'
    });
  }
  const token = cookieToken || url.searchParams.get('token');
  const modalToken = url.searchParams.get('modalToken');

  if (token) {
    loggedUserResource.set(token);
    checkpointResource.create(checkpoint.USER_HAS_SUCCESSFULLY_LOGGED_IN);

    if (window.hj) {
      const user = loggedUserResource.get() || {};

      window.hj('identify', user.email, {
        company_name: user.company?.name,
        company_id: user.company?.id
      });
    }

    // Supress error here and do not force tfa
    // It will be handled on the security page
    await fetchTfaState().catch(() => {});

    let redirectTo = getAppRoute();
    if (loggedUserResource.isAdmin() && !tfaState.hasTfaEnabled) {
      redirectTo = 'MyAccountSecuritySettingsPage';
    }

    if (redirectTo === 'LogoutPage') {
      logout();
    } else {
      const failure = await router.push({
        name: redirectTo,
        state: {
          modalToken
        }
      });

      if (!failure) {
        gtm.setup();
      }
    }
  } else {
    logout();
  }
};

export const logout = () => {
  loggedUserResource.logout();
  window.location.href = config.url.login;
  // window.location.href = config.getLoginUrl();
};
