import config from './config';
import * as Sentry from '@sentry/browser';
import errorMessageTranslator from '@/commons/services/errorMessageTranslator';
import loggedUserResource from '@/commons/resources/loggedUser';
import { isClientErrorStatus, isOkStatus, isServerErrorStatus, StatusCode } from '@/commons/services/httpStatus';

const SERVER_MESSAGES = {
  IS_OFFLINE: 'Failed to fetch'
};

const USER_MESSAGES = {
  IS_OFFLINE: 'Você parece estar offline',
  INTERNAL_SERVER_ERROR: 'Ops, ocorreu um erro! Agora nosso time já sabe e corrigirá o que for preciso.'
};

const customizedfetch = (url, options = {}, fetchConfig = {}) => {
  const defaultOptions = _buildDefaultOptions(fetchConfig);
  const fetchOptions = Object.assign({}, defaultOptions, options);

  return new Promise((resolve, reject) => {
    fetch(url, fetchOptions).then(
      response => {
        if (!fetchConfig?.ignoreUnlogged && _isUnlogged(response)) {
          window.location.href = `${config.url.login}?v=1`;
          // window.location.href = `${config.getLoginUrl()}?v=1`;
          return reject(response);
        }

        if (_isResponseWithError(response)) {
          return response.json().then(result => {
            const isServerError = isServerErrorStatus(response.status);
            const isClientError = isClientErrorStatus(response.status);

            if (isServerError || isClientError) {
              Sentry.captureException(new Error(JSON.stringify(result)), {
                extra: { url, ...options }
              });
            }

            if (isServerError) {
              result.message = USER_MESSAGES.INTERNAL_SERVER_ERROR;
            }

            if (result?.errorCode) {
              result = errorMessageTranslator.translate(result.errorCode, result.data);
            }

            return reject(result);
          });
        }

        if (fetchConfig.isBlob) {
          return resolve(response.blob());
        }

        if (!fetchConfig.isText && !_isResponseEmpty(response)) {
          return resolve(response.json());
        }

        resolve(response.text());
      },
      response => {
        if (response?.message === SERVER_MESSAGES.IS_OFFLINE) {
          response.message = USER_MESSAGES.IS_OFFLINE;
        }

        reject(response);
      }
    );
  });
};

const _isApiUrl = url => {
  return (
    url?.startsWith(config.url.api.transfeera) ||
    url?.startsWith(config.url.contacerta) ||
    url?.startsWith(config.url.api.checkpoint) ||
    url?.startsWith(config.url.api.payin)
  );
};

const _isUnlogged = response => _isApiUrl(response.url) && response.status === StatusCode.UNAUTHORIZED_STATUS_CODE;

const _isResponseWithError = response => !isOkStatus(response.status);

const _isResponseEmpty = response => Boolean(response.status === StatusCode.EMPTY_RESPONSE_STATUS_CODE);

const _buildDefaultOptions = ({ isFile, useAuth = true } = {}) => {
  const token = loggedUserResource.getToken();
  const headers = new Headers();

  if (useAuth) {
    headers.append('Authorization', `Bearer ${token}`);
  }

  if (!isFile) {
    headers.append('Content-Type', 'application/json');
  }

  return {
    method: 'GET',
    mode: 'cors',
    headers
  };
};

export default customizedfetch;
